// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// https://stackoverflow.com/questions/59703024/rails-6-rails-not-defined
import Rails from "@rails/ujs";
Rails.start();
window.Rails = Rails;

require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// 3rd party
import ahoy from "ahoy.js";
import LocalTime from "local-time";
LocalTime.start();
import "@fortawesome/fontawesome-free/js/all";
const InfiniteScroll = require("infinite-scroll");
const Masonry = require("masonry-layout");
const imagesLoaded = require("imagesloaded");
require("chartkick")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import "stylesheets/application";
import "packs/stockoji";
import "packs/autocomplete";
import { singleFileUpload, multipleFileUpload } from "packs/fileUpload";
const Choices = require("choices.js"); // https://github.com/jshjohnson/Choices
const places = require("places.js");

// Use 'DOMContentLoaded' event if not using Turbolinks
document.addEventListener("turbolinks:load", () => {
  // Masonry
  InfiniteScroll.imagesLoaded = imagesLoaded;
  var grid = document.querySelector(".masonry");

  if (grid) {
    var msnry = new Masonry(grid, {
      itemSelector: "none", // select none at first
      columnWidth: ".masonry__col-sizer",
      gutter: ".masonry__gutter-sizer",
      percentPosition: true,
      stagger: 30,
      // nicer reveal transition
      visibleStyle: { transform: "translateY(0)", opacity: 1 },
      hiddenStyle: { transform: "translateY(100px)", opacity: 0 },
    });

    // initial items reveal
    imagesLoaded(grid, function () {
      grid.classList.remove("are-images-unloaded");
      msnry.options.itemSelector = ".masonry__item";
      var items = grid.querySelectorAll(".masonry__item");
      msnry.appended(items);
    });

    // init Infinte Scroll with Masonry
    var viewMoreButton = document.querySelector(".view-more-button");
    var nextLink = document.querySelector(".pagination__next");
    if (!nextLink && viewMoreButton) {
      viewMoreButton.style.display = "none";
    }

    if (nextLink) {
      var infScroll = new InfiniteScroll(grid, {
        path: ".pagination__next",
        append: ".masonry__item",
        outlayer: msnry,
        status: ".page-load-status",
        hideNav: ".pagination__wrap",
        history: false,
        button: ".view-more-button",
        scrollThreshold: viewMoreButton ? false : 800,
      });
    }
  }

  // Uppy
  document.querySelectorAll(".uppy-upload").forEach((fileInput) => {
    if (fileInput.multiple) {
      multipleFileUpload(fileInput);
    } else {
      singleFileUpload(fileInput);
    }
  });

  // Live Search
  // https://stackoverflow.com/a/54349780
  var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  // Agolia Places Search
  var $location = document.querySelector("#location-input");
  if ($location) {
    var placesAutocomplete = places({
      appId: "plD1BEALMGBS",
      // apiKey: '41a5048d618a78fda912a29fad271f37', // production
      apiKey: "f8d8dcb958aaaa11be8cdbeb602c1c44", // development
      container: $location,
      debounce: 1000,
      autocompleteOptions: {
        minLength: 5,
      },
    });

    var $latitude = document.querySelector("#location-lat");
    var $longitude = document.querySelector("#location-lng");

    placesAutocomplete.on("change", function (e) {
      $location.textContent = e.suggestion.value;
      console.log(e.suggestion.latlng);
      $latitude.value = JSON.stringify(e.suggestion.latlng.lat);
      $longitude.value = JSON.stringify(e.suggestion.latlng.lng);
    });

    // placesAutocomplete.on('suggestions', function(e) {
    //   console.log('suggestions received')
    // });

    placesAutocomplete.on("clear", function () {
      $location.textContent = "none";
      $latitude.value = "";
      $longitude.value = "";
    });

    if ($location.value != "") {
      document.querySelector(".ap-icon-clear").style.display = "block";
      document.querySelector(".ap-icon-pin").style.display = "none";
    }
  }

  // Choices
  // https://github.com/jshjohnson/Choices
  var photoTags = document.getElementById("photo-tags");
  if (photoTags) {
    photoTags = new Choices(photoTags, {
      delimiter: ",",
      paste: false,
      duplicateItemsAllowed: false,
      editItems: true,
      removeItemButton: true,
    });
  }
});
