const autoComplete = require("@tarekraafat/autocomplete.js/dist/js/autoComplete");

// Use 'DOMContentLoaded' event if not using Turbolinks
document.addEventListener("turbolinks:load", () => {
  // autoComplete.js on typing event emitter
  document
    .querySelector("#autoComplete")
    .addEventListener("autoComplete", (event) => {
      // console.log(event);
    });

  // The autoComplete.js Engine instance creator
  const autoCompletejs = new autoComplete({
    data: {
      src: async () => {
        // Loading placeholder text
        document
          .querySelector("#autoComplete")
          .setAttribute("placeholder", "Loading...");
        // Fetch External Data Source
        const query = document.querySelector("#autoComplete").value;
        const source = await fetch(
          // "https://tarekraafat.github.io/autoComplete.js/demo/db/generic.json"
          `/search/autocomplete.json?query=${query}`

        );
        const data = await source.json();
        // Post loading placeholder text
        document
          .querySelector("#autoComplete")
          .setAttribute("placeholder", "Search for photos");
        // Returns Fetched data
        return data.results;
      },
      key: ["tag", "country", "state", "city", "user"],
      cache: false,
    },
    placeHolder: "Search for photos",
    selector: "#autoComplete",
    threshold: 0,
    debounce: 300,
    searchEngine: "strict",
    highlight: true,
    maxResults: 5,
    resultsList: {
      render: true,
    },

    onSelection: (feedback) => {
      
      const key = feedback.selection.key;
      var selection = null;
      // console.log(key);
      if (key == "tag") {
        selection = feedback.selection.value.tag;
      }
      if (key == "country") {
        selection = feedback.selection.value.country;
      }
      if (key == "state") {
        selection = feedback.selection.value.state;
      }
      if (key == "city") {
        selection = feedback.selection.value.city;
      }
      if (key == "user") {
        selection = feedback.selection.value.user;
      }
      // console.log(selection);
      // Clear Input
      document.querySelector("#autoComplete").value = selection;
      // Submit #search_form with the selected value
      var form = document.querySelector("#search_form");
      // Rails.fire(form, "submit");
      form.submit();
      // Concole log autoComplete data feedback
      // console.log(feedback);
    },
  });

  // Toggle event for search input
  // showing & hidding results list onfocus / blur
  ["focus", "blur", "keydown"].forEach(function (eventType) {
    const resultsList = document.querySelector("#autoComplete_list");

    document
      .querySelector("#autoComplete")
      .addEventListener(eventType, function (e) {
        // Hide results list
        if (eventType === "blur") {
          resultsList.style.display = "none";
        } else if (eventType === "focus") {
          // Show results list
          resultsList.style.display = "block";
        } else if (eventType === "keydown")  {
          // Hide results list
          if (e.keyCode == 27) {
            //window.close();
            resultsList.style.display = "none";
          } else if (e.keyCode != 27) {
            // Show results list
            resultsList.style.display = "block";
          }
        }
      });
  });
});
