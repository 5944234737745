// Imports
document.addEventListener("turbolinks:load", function (e) {
	// Begin Mobile Small devices menubar
	var toggle = document.getElementById("nav-toggler");
	var topNav = document.getElementById("navigation");

	// Listen for click event on toggle var
	toggle.addEventListener(
		"click",
		function () {
			// console.log("toggle clicked");
			// toggle class "hidden" on topNav var
			topNav.classList.toggle("hidden");
		},
		false
	);
	// End Mobile Small devices menubar
});
